import React from "react"
import { Content, Layout, Heading1, Heading2, SEO } from "../../components"

const HelpFileUrls = () => (
  <Layout>
    <SEO title="Pick colors from local file URLs" />
    <Content>
      <Heading1>Pick colors from local file URLs</Heading1>

      <p>
        Extensions are normally not allowed to pick colors from local <code>file://</code> URL
        addresses.
      </p>

      <p>
        If you really need it, you can enable <strong>Allow access to file URLs</strong> option in
        Chrome Extensions page.
      </p>

      <p>
        Because this allows <em>extension</em> to access your local files and thus introducing
        potentional security problem, this can't be done from extension settings, but must be
        explicitly allowed through <em>Chrome's</em> settings.
      </p>

      <Heading2>Is it secure?</Heading2>
      <p>
        As <strong>Eye Dropper</strong> can't access all pages but only current one, it might be.
      </p>

      <p>
        I would recommend against enabling this (at least permanently) because extension can get
        broader permissions later getting access to
        <strong>all files on your computer</strong>. I'm not planning to require more permissions in
        Eye Dropper currently, but be carefull anyway.
      </p>

      <Heading2>How to enable this</Heading2>

      <p>If you are aware of the risks, lets do it:</p>

      <ol>
        <li>
          Open <code>chrome://extensions</code> page
        </li>
        <li>
          Find <strong>Eye Dropper</strong> extension there
        </li>
        <li>
          Click on <code>Details</code>
        </li>
        <li>
          Enable <code>Allow access to file URLs</code>
        </li>
      </ol>

      <p>You can of course enable/disable it in same manner any time you need.</p>
    </Content>
  </Layout>
)

export default HelpFileUrls
